<template>
  <div class="container-fluid">
    <div class="col-sm-12 mx-auto py-3 d-flex flex-column">
      <div class="d-flex my-5 justify-content-center">
        <img src="@/assets/logo/spinner.png" class="" alt="logo" width="10%" />
      </div>

      <b-form class="p-2 col-12 col-sm-6 mx-auto">
        <b-row class="fullNameInputs" align-h="center">
          <b-col cols="12" sm="6">
            <b-form-group id="input-group-1" label-for="firstNameI">
              <b-form-input
                id="firstNameI"
                type="text"
                placeholder="الأسم الأول"
                class="rounded-form"
                v-model.lazy="form.FirstNameI"
                @blur="$v.form.FirstNameI.$touch"
                :state="validateState('FirstNameI')"
              ></b-form-input>
              <b-form-invalid-feedback class="text-right mx-2"
                >{{ $t("reqfirst") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group id="LastNameI" label-for="LastNameI">
              <b-form-input
                id="LastNameI"
                type="text"
                placeholder="الأسم الأخير"
                class="rounded-form"
                v-model.lazy="form.LastNameI"
                @blur="$v.form.LastNameI.$touch"
                :state="validateState('LastNameI')"
              ></b-form-input>
              <b-form-invalid-feedback class="text-right mx-2"
                >{{ $t("reqlast") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group id="emailInput" label-for="emailInput">
          <b-form-input
            id="emailInput"
            type="email"
            placeholder="الإيميل"
            class="rounded-form"
            v-model.lazy="form.MailI"
            @blur="$v.form.MailI.$touch"
            :state="validateState('MailI')"
          ></b-form-input>
          <b-form-invalid-feedback class="text-right mx-2"
            >{{ $t("reqemail") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="Inquirynput" label-for="Inquirynput">
          <b-form-textarea
            id="Inquirynput"
            placeholder="الاستفسار"
            class="rounded-form"
            v-model.lazy="form.Inquiry"
            @blur="$v.form.Inquiry.$touch"
            :state="validateState('Inquiry')"
          >
          </b-form-textarea>
          <b-form-invalid-feedback class="text-right mx-2"
            >{{ $t("inquirylength") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-button variant="primary" pill block @click="send()">{{
            $t("send")
          }}</b-button>
        </b-form-group>
      </b-form>
      <div>
        <p class="text-center">{{ $t("Follow Us") }}</p>
      </div>
      <div class="mx-auto mb-3 row">
        <b-avatar
          size="3.2em"
          href="https://t.me/yamhad"
          style="background-color: transparent"
          class="align-middle mx-2 mb-2 col"
        >
        <img
            src="@/assets/icons/Telegram.png"
            class="img-thumbnail p-0 border-0"
            alt=""
          />
          </b-avatar>
        <b-avatar
          size="3.2em"
          href="https://www.pinterest.de/Yamhad_online/"
          style="background-color: transparent"
           class="align-middle mx-2 mb-2 col"
        >
          <img
            src="@/assets/icons/Pinterest.png"
            class="img-thumbnail p-0 border-0"
            alt=""
          />
        </b-avatar>
        <b-avatar
          size="3.2em"
          href="https://wa.me/971565628959?text= مرحباً، أريد الاستفسار عن منتجاتكم"
          style="background-color: transparent"
          class="align-middle mx-2 mb-2 col"
          @click="whatsAppEvent"
        >
          <img
            src="@/assets/icons/Whats app.png"
            class="img-thumbnail p-0 border-0"
            alt=""
        /></b-avatar>
        <b-avatar
          size="3.2em"
          href="https://www.facebook.com/Yamhad_online-105471422081046/"
          style="background-color: transparent"
          class="align-middle mx-2 mb-2 col"
        >
          <img
            src="@/assets/icons/facebook.png"
            class="img-thumbnail p-0 border-0"
            alt=""
        /></b-avatar>
        <b-avatar
          size="3.2em"
          href="https://www.instagram.com/yamhad__china"
          style="background-color: transparent"
          class="  align-middle mx-2 mb-2 col"
        >
          <img
            src="@/assets/icons/IG.png"
            class="img-thumbnail p-0 border-0"
            alt=""
          />
        </b-avatar>

        <b-avatar
          size="3.2em"
          style="background-color: transparent"
          href="mailto:support@yamhad.net"
          class="align-middle mx-2 mb-2 col"
        >
          <img
            src="@/assets/icons/email.png"
            class="img-thumbnail p-0 border-0"
            alt=""
          />
        </b-avatar>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import i18n from "../main.js";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  components: {},
  metaInfo() {
    return {
      title: "Contact Us",

      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "تواصلوا معنا عبر الواتس أب عبر الرقم: +(971) 56 562 8959 للوصول إلى خدمة العملاء والإجابة على أي استفسار.",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["Token", "User"]),
    isLoggedIn() {
      if (!_.isEmpty(this.User) && !_.isEmpty(this.Token)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      form: {
        FirstNameI: "",
        LastNameI: "",
        MailI: "",
        Inquiry: "",
      },
      show: false,
    };
  },
  validations: {
    form: {
      FirstNameI: {
        required,
        minLength: minLength(3),
      },
      LastNameI: {
        required,
        minLength: minLength(3),
      },

      MailI: {
        required,
        email,
      },
      Inquiry: {
        required,
        minLength: minLength(15),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    send() {
      this.$v.$touch();
      var self = this;
      if (!this.$v.$invalid) {
        var url = process.env.VUE_APP_BASEURLPROD + "/sendMail";
        axios.post(url, this.form).then(function(response) {
          if (response) {
            self.form.FirstNameI = "";
            self.form.LastNameI = "";
            self.form.MailI = "";
            self.form.Inquiry = "";
            self.$v.$reset();
            self.$bvModal
              .msgBoxOk(i18n.t("DataSubmitted"), {
                size: "sm",
                buttonSize: "sm",
                okVariant: "primary",
                okTitle: i18n.t("ok"),
                headerClass: "p-2 border-bottom-0 font-weight-bold",
                footerClass: "p-2 border-top-0 mx-auto ",
                modalClass: " p-1 pt-3 text-center ",
                bodyClass: " pt-2 ",
                centered: true,
              })
              .then((value) => {
                if (value) {
                  self.$router.push({ name: "Home" });
                }
              });
          }
        });
      }
    },
    whatsAppEvent() {
      this.$gtag.event("E_WhatsAPP", { method: "Google" });
    },
  },
  mounted() {
    this.$gtag.event("Contacts Page", { method: "Google" });

    if (this.isLoggedIn) {
      this.form.MailI = this.User.user_email;
    }
  },
};
</script>

<style scoped>
.insta {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
</style>
